<template>
  <CreditCardTemplate
    :formFlag="formFlag"
    :hideUpload="hideUpload"
    :resultFlag="resultFlag"
    :successFlag="successFlag"
    :icon_show_success.sync="icon_show_success"
    :icon_show_fail="icon_show_fail"
    :resultMessage.sync="resultMessage"
    :userId="userId"
    :paymentDepositId.sync="paymentDepositId"
  >
    <div class="form-box">
      <p class="upgrade">{{ $t('deposit.default.upgrade') }}</p>
      <p>{{ $t('deposit.cc.header') }}</p>
      <el-form label-position="top" :model="form" ref="form" :rules="rules" status-icon>
        <div class="form-content clearfix">
          <div class="top">
            <AccountNumber
              supportedCurrencies="all"
              @setCurrency="setCurrency"
              @setAccountNumber="setAccountNumber"
            ></AccountNumber>

            <el-form-item :label="setAmtLabel(accountCurrency, form.amount)" prop="amount">
              <numeric-input v-model="form.amount" :currency="accountCurrency" :precision="2"></numeric-input>
            </el-form-item>

            <el-form-item
              :label="$t('deposit.cc.field.fundNow')"
              prop="accountNumber"
              v-if="form.accountNumber && card.cardOptions.length > 0"
            >
              <el-select v-model="card.selected" data-testid="accountNumber">
                <el-option
                  v-for="(item, index) in card.cardOptions"
                  :key="index"
                  :value="item.id"
                  :label="setCardLabel(item)"
                  :data-testid="item.id"
                ></el-option>
                <el-option :label="$t('deposit.cc.field.newCard')" :value="0"></el-option>
              </el-select>
            </el-form-item>

            <el-form-item
              :label="$t('deposit.cc.field.cardNum')"
              prop="masterCreditCard"
              v-if="card.cardOptions.length == 0 || card.selected == '0'"
            >
              <el-input v-model="form.masterCreditCard" data-testid="creditCard"></el-input>
            </el-form-item>

            <el-form-item v-else :label="$t('deposit.cc.field.cardNum')" prop="oldCreditCardMiddle">
              <div class="ccNumber clearfix">
                <el-input :disabled="true" :value="form.oldCreditCardFirst" data-testid="oldCreditCardFirst"></el-input>
                <el-form-item>
                  <el-input
                    v-model="form.oldCreditCardMiddle"
                    maxlength="7"
                    data-testid="oldCreditCardMiddle"
                  ></el-input>
                </el-form-item>
                <el-input :disabled="true" :value="form.oldCreditCardLast" data-testid="oldCreditCardLast"></el-input>
              </div>
            </el-form-item>

            <el-form-item prop="nameCard" class="nameCard">
              <p class="required-label">
                <span class="required_icon">*</span>{{ $t('deposit.cc.field.nameOnCard') }}
                <img src="@/assets/images/label_icon.png" alt class="label-icon" />
                <i18n path="common.fund.infoHover" tag="hover">
                  <template v-slot:must>
                    <u>{{ $t('common.fund.must') }}</u>
                  </template>
                </i18n>
              </p>
              <el-input
                v-model="form.nameCard"
                maxlength="32"
                :disabled="card.selected != ''"
                data-testid="nameCard"
              ></el-input>
            </el-form-item>
          </div>

          <div class="bottom">
            <div class="left">
              <el-form-item :label="$t('deposit.cc.field.bankCode')" prop="bankCode">
                <el-input
                  v-model="form.bankCode"
                  :placeholder="$t('deposit.cc.placeholder.bankCode')"
                  data-testid="bankCode"
                ></el-input>
              </el-form-item>

              <el-form-item :label="$t('deposit.cc.field.expDate')" prop="month">
                <div class="date clearfix">
                  <el-form-item prop="month" class="month fl">
                    <Month :year="form.year" v-model="form.month"></Month>
                  </el-form-item>
                  <el-form-item prop="year" class="year fr">
                    <Year v-model="form.year"></Year>
                  </el-form-item>
                </div>
              </el-form-item>

              <el-form-item :label="$t('deposit.cc.field.cvv')" class="security" prop="securityCode">
                <span class="cvvTip" v-html="$t('deposit.cc.cvvTip')"></span>
                <el-input v-model="form.securityCode" maxlength="4" data-testid="securityCode"></el-input>
              </el-form-item>
            </div>
            <div class="right">
              <img src="@/assets/images/channel/master_img.png" alt />
            </div>
          </div>

          <div class="bottom">
            <el-button class="btn-blue" :loading="loading" @click="submitForm()" data-testid="submit">
              {{ $t('common.button.submit') }}
            </el-button>
          </div>
        </div>
      </el-form>
      <div v-html="bankHTML"></div>
    </div>
  </CreditCardTemplate>
</template>

<script>
import NumericInput from '@/components/NumericInput';
import AccountNumber from '@/components/form/AccountNumber';
import Month from '@/components/form/Month';
import Year from '@/components/form/Year';
import Hover from '@/components/Hover';
import CreditCardTemplate from '@/components/template/deposit/CreditCardTemplate';
import { autoForm } from '@/util/getAutoForm';
import { apiCc_payment_apg } from '@/resource';
import mixin from '@/mixins/page/deposit/cc';
import creditCard from '@/mixins/page/deposit/creditCard';

export default {
  name: 'ApgDeposit',
  mixins: [mixin, creditCard],
  components: {
    NumericInput,
    AccountNumber,
    Month,
    Year,
    Hover,
    CreditCardTemplate
  },
  methods: {
    submitDeposit() {
      let card = this.form.masterCreditCard;
      if (this.form.masterCreditCard == null || this.form.masterCreditCard == '') {
        card = this.form.oldCreditCardFirst + this.form.oldCreditCardMiddle + this.form.oldCreditCardLast;
      }
      card = card.replace(/[^\d]/g, '');

      return apiCc_payment_apg({
        mt4Account: this.form.accountNumber,
        paymentMethod: 'creditcard',
        depositAmount: this.form.amount,
        cardNumber: card,
        cardHolderName: this.form.nameCard,
        expireYear: this.form.year,
        expireMonth: this.$options.filters.appendzero(this.form.month),
        cvv: this.form.securityCode,
        cardExist: this.form.oldCreditCardFirst ? true : false,
        bankCode: this.form.bankCode
      });
    },
    successMethod(result) {
      this.bankHTML = autoForm(result.data.msg);
      this.$nextTick(function() {
        if (document.getElementsByName('echoForm')[0]) {
          document.getElementsByName('echoForm')[0].submit();
        } else if (document.getElementsByName('theform')[0]) {
          document.getElementsByName('theform')[0].submit();
        }
      });
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/pages/deposit/creditOrDebit.scss';
</style>
